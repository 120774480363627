import autocomplete from '@/components/autocomplete/index.vue'
import { mapActions, mapGetters, mapMutations } from "vuex";
// import buyFlowMixin from '@/mixins/buy-flow'

export default {
    name: "delivery-zasilkovna",
    // mixins: [buyFlowMixin],
    data() {
        return {
            cityList: [],
            filterCityList: [],
            selectedCity: null,
            selectedCityRef: null,
            selectedBranch: null,
            isTownsLoading: false,
            isDepartmensLoading: false,
        }
    },
    props: {
        cityError: {
            type: Boolean,
            default: true,
        },
        branchError: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        autocomplete
    },
    computed: {
        ...mapGetters({
            cities: "zasilkovna/cities",
            branches: "zasilkovna/branches",
        }),
    },
    watch: {
    },
    created() {
        this.isTownsLoading = true;
        this.fetchCity().then(res => {
            this.cityList = res;
            this.filterCityList = res;
        }).finally(() => {
            this.isTownsLoading = false;
        });
    },
    methods: {
        ...mapActions({
            fetchCity: "zasilkovna/GET_CITY_LIST",
            fetchBranch: "zasilkovna/GET_BRANCH_LIST",
        }),

        handleCityInput(e) {
            if (e.length < 3) {
                this.filterCityList = this.cityList.slice();
                return;
            }
            this.filterCityList = this.cityList.filter(item => item.name.toLowerCase().includes(e.toLowerCase()));
        },

        handleBranchInput(e) {
            if (e.length < 1) {
                this.fetchBranch({ city: this.selectedCity });
            }
            this.fetchBranch({ city: this.selectedCity, search: e });
        },


        async handleCitySelect(e) {
            this.selectedCityRef = e.id;
            this.selectedCity = e.name;
            this.isDepartmensLoading = true;
            await this.fetchBranch({ city: this.selectedCity });
            this.isDepartmensLoading = false;
            this.$refs.branchSelector.$el.querySelector('input').value = ''
            this.$emit('select-city', e);
            this.selectedBranch = '';
        },
        handleBranchSelect(e) {
            this.$emit('select-branch', e);
        }
    },
};
